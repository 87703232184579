<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span
          ><v-divider class="mx-4" inset vertical></v-divider>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="isEdit">
                <v-text-field
                  v-model="item.ParmId"
                  label="รหัส"
                  dense
                  required
                  readonly
                  ref="ParmId"
                  @keydown.enter="NextFocus('CompanyName')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.CompanyName"
                  label="ชื่อบริษัท"
                  dense
                  required
                  ref="CompanyName"
                  @keydown.enter="NextFocus('CompanyAddress')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.CompanyAddress"
                  label="ที่อยู่"
                  dense
                  required
                  ref="CompanyAddress"
                  @keydown.enter="NextFocus('CompanyTaxId')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.CompanyTaxId"
                  label="หมายเลขภาษี"
                  dense
                  required
                  ref="CompanyTaxId"
                  @keydown.enter="NextFocus('CompanyPhone')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.CompanyPhone"
                  label="เบอร์โทร"
                  dense
                  required
                  ref="CompanyPhone"
                  @keydown.enter="NextFocus('DiningTime')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.number="item.DiningTime"
                  label="เวลา(นาที)"
                  type="number"
                  dense
                  required
                  ref="DiningTime"
                  @keydown.enter="NextFocus('AdultPrice')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.number="item.AdultPrice"
                  label="ราคาผู้ใหญ่"
                  type="number"
                  dense
                  required
                  ref="AdultPrice"
                  @keydown.enter="NextFocus('ChildPrice')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.number="item.ChildPrice"
                  label="ราคาเด็ก"
                  type="number"
                  dense
                  required
                  ref="ChildPrice"
                  @keydown.enter="NextFocus('LastOrderTimeStr')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.LastOrderTimeStr"
                  label="เวลาสั่งอาหารสุดท้าย"
                  type="time"
                  suffix="EST"
                  dense
                  required
                  ref="LastOrderTime"
                  @keydown.enter="NextFocus('PathImage')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.PathImage"
                  label="ที่อยู่รูปภาพ"
                  dense
                  required
                  ref="PathImage"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" v-if="isEdit">
                <v-autocomplete
                  v-model="item.Status"
                  :items="enumStatus"
                  item-value="id"
                  item-text="name"
                  :return-object="false"
                  dense
                  label="สถานะ"
                ></v-autocomplete>
              </v-col> -->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" @click="cancel"> ยกเลิก </v-btn>
          <v-btn class="primary" @click="submit"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseEnum from "@js/enum";
import { mapActions, mapState } from "vuex";

export default {
  props: ["modal", "item", "itemIndex"],
  data() {
    return {
      enumStatus: baseEnum.status.data,
      dialog: false,
      itemEdit: {},
      indexEdit: -1,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    dialogTitle() {
      return this.indexEdit == -1 ? "เพิ่ม" : "แก้ไข";
    },
    isEdit() {
      return this.indexEdit == -1 ? false : true;
    },
  },
  methods: {
    ...mapActions("settings", ["actParameterUpdate"]),
    closeModal() {
      this.$emit("close", false);
    },
    cancel() {
      this.closeModal();
    },
    submit() {
      if (this.indexEdit == -1) {
        //เพิ่ม
        this.itemEdit.ModifiedBy = this.user.UserId;
      } else {
        //แก้ไข
        this.itemEdit.ModifiedBy = this.user.UserId;
        this.actParameterUpdate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            this.closeModal();
          } else {
            this.Error(data.message);
          }
        });
      }
    },
  },
  watch: {
    modal() {
      this.dialog = this.modal;
    },
    item() {
      this.itemEdit = this.item;
    },
    itemIndex() {
      this.indexEdit = this.itemIndex;
    },
  },
};
</script>

<style></style>
